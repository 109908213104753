/* Basic Accordion */
.basic-accordion .card {
    border: 2px solid #e2e2e2;
    border-radius: 6px;
    margin-bottom: 4px;
}
.basic-accordion .card-header {
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    padding: 0;
    position: relative;
}
.basic-accordion .card-header section > div {
    padding: 13px 19px;
    cursor: pointer;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
}
.basic-accordion .card-header section > div.collapsed { color: #888ea8; }
.basic-accordion .card-header section > div:not(.collapsed) {
    color: #2262c6;
    border-bottom: 2px solid #e2e2e2;
    font-weight: 600;
}
.basic-accordion .card-header section > div .icons {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.basic-accordion .card .card-body p {
    color: #888ea8;
    letter-spacing: 1px;
    font-size: 13px;
}
.basic-accordion .card .card-body p:not(:last-child) { margin-bottom: 10px; }
.basic-accordion .card .card-body ul { margin-bottom: 0; }
.basic-accordion .card .card-body ul li {
    font-size: 12px;
    letter-spacing: 1px;
}
.basic-accordion .card .card-body ul li:not(:last-child) { margin-bottom: 5px; }
/* Link Accordion */
.link-accordion .card-header {
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    padding: 0;
    position: relative;
}
.link-accordion .card-header a.collapsed { 
    color: #888ea8;
    font-weight: 600;
    padding: 0px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
    transition: all ease 0.3s;
}
.link-accordion .card-header a.collapsed:hover { 
    color: #2262c6;
    transition: all ease 0.3s;
}
.link-accordion .card-header a:not(.collapsed) {
    color: #2262c6;
    font-weight: 600;
    padding: 0px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
}
.link-accordion .card {
    border: 2px solid #e2e2e2;
    border-radius: 6px;
    margin-bottom: 4px;
}
/* Basic Accordion With Icon*/
.basic-accordion-icon .card {
    border: 2px solid #e2e2e2;
    border-radius: 6px;
    margin-bottom: 4px;
}
.basic-accordion-icon .card-header {
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    padding: 0;
    position: relative;
}
.basic-accordion-icon .card-header section > div {
    padding: 13px 19px;
    cursor: pointer;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
}
.basic-accordion-icon .card-header section > div.collapsed { color: #888ea8; }
.basic-accordion-icon .card-header section > div:not(.collapsed) {
    color: #2262c6;
    border-bottom: 2px solid #e2e2e2;
    font-weight: 600;
}
.basic-accordion-icon .card-header section > div .icons {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.basic-accordion-icon .card .card-body p {
    color: #888ea8;
    letter-spacing: 1px;
    font-size: 13px;
}
.basic-accordion-icon .card .card-body p:not(:last-child) { margin-bottom: 10px; }
.basic-accordion-icon .card .card-body ul { margin-bottom: 0; }
.basic-accordion-icon .card .card-body ul li {
    font-size: 12px;
    letter-spacing: 1px;
}
.basic-accordion-icon .card .card-body ul li:not(:last-child) { margin-bottom: 5px; }
/* Basic Accordion With Icon rotate */
.basic-accordion-icon.rotate .card-header section > div i.has-rotate{
    transform: rotate(0deg);
    transition: all ease 0.3s;
 }
.basic-accordion-icon.rotate .card-header section > div:not(.collapsed) i.has-rotate{
   transform: rotate(180deg);
   transition: all ease 0.3s;
}
/*  No Outer Spacing */
.no-outer-spacing {
    border: 2px solid #e0e6ed;
    border-radius: 6px;
}
.no-outer-spacing .card {
    margin-bottom: 0;
    border: none;
    border-radius: 0;
}
.no-outer-spacing .card:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.no-outer-spacing .card:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.no-outer-spacing .card:not(:last-child) { border-bottom: 2px solid #e0e6ed; }
.no-outer-spacing .card-header section > div:not(.collapsed) { border-bottom: none; }
/* Accordin with Icons */
.accordion-icons .accordion-icon {
    display: inline-block;
    margin-right: 10px
}
.accordion-icons .accordion-icon svg {
    color: #888ea8;
    margin-right: 6px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    fill: rgba(0, 23, 55, 0.08);
}
.accordion-icons div:not(.collapsed) .accordion-icon svg {
    color: #2262c6;
    fill: rgba(27, 85, 226, 0.23921568627450981);
}